/* eslint-disable @typescript-eslint/no-unused-vars */
import { useQuery } from '@apollo/client';
import { InputField } from '@components/InputField';
import { SecondaryButton } from '@components/buttons/SecondaryButton';
import { Box, Grid, Stack, Typography, IconButton } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { dusk, midnight, white } from 'src/constants/colors';
import {
  PersistentStorageQuery as PersistentStorageQueryResponse,
  EphemeralStorageQuery as EphemeralStorageQueryResponse,
  useEphemeralStorageQuery,
} from 'src/services/gql/generated';
import { PersistentStorageQuery } from 'src/services/gql/queries/PersistentStorageQuery.gql';
import PersistantStorageTable from './PersistantStorageTable';
import { ReactComponent as IconClearBox } from 'src/assets/icn-clear-circle.svg';
import EphemeralStorageTable from './EphemeralStorageTable';
import { useFormMessageBarContext } from '@components/cardConfig/form/context/FormMessageBarContext';

const StorageTab: React.FC = () => {
  const { data: persistantListData, refetch: queryPersistantData } =
    useQuery<PersistentStorageQueryResponse>(PersistentStorageQuery, {
      variables: { first: 10 },
    });
  const [loading, setIsLoading] = useState<boolean>(false);
  const [isQueryPersistantData, setIsQueryPersistantData] =
    useState<boolean>(false);
  const [ephemeralData, setEphemeralData] = useState<
    EphemeralStorageQueryResponse | undefined
  >();
  const [queryPersistantValue, setQueryPersistantValue] =
    React.useState<string>('');
  const [queryValue, setQueryValue] = React.useState<string>('');

  const { refetch } = useEphemeralStorageQuery();
  const { setErrorMessage } = useFormMessageBarContext();

  const handlePersistantQuery = useCallback(
    async (pagination?: 'next' | 'back') => {
      const endCursor =
        persistantListData?.persistentStorage?.pageInfo?.endCursor;

      setIsQueryPersistantData(true);
      setIsLoading(true);
      try {
        const { data } = await queryPersistantData({
          PersistentStorageQueryInput: {
            keys: queryPersistantValue ? [queryPersistantValue] : [],
          },
          after: pagination === 'next' ? endCursor : undefined,
        });
      } catch (error: unknown) {
        if (error instanceof Error) {
          setErrorMessage(error.message);
          return;
        }
      } finally {
        setIsLoading(false);
      }
    },
    [
      queryPersistantValue,
      queryPersistantData,
      setErrorMessage,
      persistantListData,
    ],
  );

  const clearPersistantData = useCallback(async () => {
    setQueryPersistantValue('');
    setIsLoading(true);
    const { data } = await queryPersistantData({
      PersistentStorageQueryInput: { keys: [] },
    });
    setIsQueryPersistantData(false);
    setIsLoading(false);
  }, [queryPersistantData]);

  const handleQuery = useCallback(async () => {
    try {
      const { data } = await refetch({
        ephemeralStorageQueryInput: { keys: [queryValue] },
      });
      setEphemeralData(data);
    } catch (error: unknown) {
      setEphemeralData(undefined);
      if (error instanceof Error) {
        setErrorMessage(error.message);
        return;
      }
    }
  }, [queryValue, refetch, setErrorMessage]);

  return (
    <Stack spacing={2}>
      <Grid container sx={{ flexDirection: 'column' }}>
        <Typography
          sx={{
            color: dusk,
            fontSize: '22px',
            fontWeight: 900,
            marginTop: '16px',
          }}
        >
          Persistant Storage
        </Typography>
        {(persistantListData?.persistentStorage &&
          persistantListData?.persistentStorage?.nodes?.length) ||
        isQueryPersistantData ? (
          <PersistantStorageTable
            data={persistantListData?.persistentStorage?.nodes}
            loading={loading}
            pageInfo={persistantListData?.persistentStorage?.pageInfo}
            queryPersistantValue={queryPersistantValue}
            setQueryPersistantValue={setQueryPersistantValue}
            handlePersistentQuery={handlePersistantQuery}
            clearPersistantData={clearPersistantData}
          />
        ) : (
          <Box
            sx={{
              backgroundColor: white,
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              justifyContent: 'center',
              mb: 1,
              py: 5,
              px: 4,
              mt: '15px',
              borderRadius: '8px',
              textAlign: 'center',
            }}
          >
            <Typography
              sx={{
                color: midnight,
                fontSize: '22px',
                fontWeight: 900,
                lineHeight: 'normal',
                mb: '8px',
              }}
            >
              No stored data
            </Typography>
            <Typography
              sx={{
                color: dusk,
                fontSize: '18px',
                fontWeight: 700,
                lineHeight: 'normal',
                maxWidth: '647px',
              }}
            >
              When your on-chain topic parsers run, stored data will be exposed.
              You will be able to add and modify keys/values here. Learn more in
              the storage documentation.
            </Typography>
            <SecondaryButton
              onClick={() =>
                window.open(
                  'https://docs.notifi.network/docs/next/notifi-hosted-development/storage-providers#admin-portal-storage-editor',
                  '_blank',
                )
              }
              buttonTitle="Learn More"
              customSx={{ mt: '16px', minWidth: '118px' }}
            />
          </Box>
        )}
      </Grid>
      <Grid container sx={{ flexDirection: 'column' }}>
        <Typography
          sx={{
            color: dusk,
            fontSize: '22px',
            fontWeight: 900,
            marginTop: '16px',
          }}
        >
          Ephemeral Storage
        </Typography>
        <Box
          sx={{
            backgroundColor: white,
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'wrap',
            mt: '15px',
            borderRadius: '8px',
          }}
        >
          <Stack direction="row" alignItems="center" gap={2} px={5} pt={4}>
            <Stack flexDirection="column">
              <Grid sx={{ position: 'relative' }}>
                <InputField
                  label="Query for ephemeral keys"
                  value={queryValue}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleQuery();
                    }
                  }}
                  placeholder=""
                  customSx={{
                    borderRadius: '4px',
                    width: '350px',
                    mt: 0,
                    '& .MuiInputBase-input.Mui-disabled': {
                      WebkitTextFillColor: midnight,
                    },
                  }}
                  inputAttrProps={{ maxLength: 20 }}
                  onChange={(e) => {
                    setQueryValue((e.target as HTMLInputElement).value);
                  }}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        size="small"
                        onClick={() => setQueryValue('')}
                        sx={{ color: midnight, opacity: queryValue ? 1 : 0 }}
                      >
                        <IconClearBox />
                      </IconButton>
                    ),
                  }}
                />
              </Grid>
            </Stack>
            <SecondaryButton
              buttonTitle="Query"
              customSx={{ minWidth: '84px' }}
              onClick={handleQuery}
              disabled={!queryValue?.trim()?.length}
            />
          </Stack>

          <Stack sx={{ mt: '16px' }}>
            {ephemeralData !== undefined ? (
              <EphemeralStorageTable
                refetchQuery={handleQuery}
                data={ephemeralData.ephemeralStorage?.nodes}
              />
            ) : null}
          </Stack>
        </Box>
      </Grid>
    </Stack>
  );
};

export default StorageTab;
