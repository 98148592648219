import React, { useCallback, useRef, useState } from 'react';
import { ReactComponent as IconClearBox } from 'src/assets/icn-clear-circle.svg';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Menu,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import {
  dusk,
  eggshell,
  midnight,
  blueglass,
  white,
} from 'src/constants/colors';
import { Delete, Edit } from '@mui/icons-material';
import {
  PersistentStorageEntry,
  useUpdatePersistentStorageMutation,
  PageInfo,
} from 'src/services/gql/generated';
import ModifyKeyDialog from './ModifyKeyDialog';
import { ConfirmDialog } from '@components/cardConfig/ConfirmDialog';
import { PersistentStorageQuery } from 'src/services/gql/queries/PersistentStorageQuery.gql';
import { formatDate } from 'src/util/dateUtils';
import { useFormMessageBarContext } from '@components/cardConfig/form/context/FormMessageBarContext';
import { ReactComponent as IconClose } from '../../assets/icn-cancel.svg';
import { useFeatureFlag } from '@components/auth/FeatureFlagContext';
import { InputField } from '@components/InputField';
import { SecondaryButton } from '@components/buttons/SecondaryButton';
import Pagination from '@components/Pagination';

type Props = {
  data?: Array<PersistentStorageEntry>;
  pageInfo?: Pick<PageInfo, 'hasNextPage'>;
  queryPersistantValue: string;
  setQueryPersistantValue: (value: string) => void;
  handlePersistentQuery: (pagination?: 'next' | 'back') => void;
  clearPersistantData: () => void;
  loading: boolean;
};

const PersistantStorageTable: React.FC<Props> = ({
  data,
  loading,
  pageInfo,
  queryPersistantValue,
  setQueryPersistantValue,
  handlePersistentQuery,
  clearPersistantData,
}) => {
  const { isAP2Enabled } = useFeatureFlag();
  const [isEditOpen, setIsEditOpen] = useState<boolean>(false);
  const [mKey, setMKey] = useState<string>('');
  const [mValue, setMValue] = useState<string>('');
  const [isConfirmRemoveOpen, setIsConfirmRemoveOpen] = useState(false);
  const [keyToRemove, setKeyToRemove] = useState<string>('');
  const [viewValue, setViewValue] = useState<string>('');
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isOpenView = Boolean(anchorEl);
  const [updatePersistentStorageMutation] =
    useUpdatePersistentStorageMutation();
  const isBackButtonDisabled = useRef(true);

  const { setErrorMessage } = useFormMessageBarContext();

  const handleEdit = ({ key, value }: { key: string; value: string }) => {
    setMKey(key);
    setMValue(value);
    setIsEditOpen(true);
  };

  const handleOpenView = (
    event: React.MouseEvent<HTMLElement>,
    viewValue: string,
  ) => {
    setAnchorEl(event.currentTarget);
    setViewValue(viewValue);
  };
  const handleCloseView = () => {
    setAnchorEl(null);
  };

  const handleConfirmRemove = async (keyToRemove: string) => {
    try {
      await updatePersistentStorageMutation({
        variables: {
          updatePersistentStorageInput: {
            entries: [{ key: keyToRemove }],
          },
        },
        refetchQueries: [
          {
            query: PersistentStorageQuery,
          },
        ],
      });
    } catch (error) {
      setErrorMessage((error as Error).message);
    } finally {
      setIsConfirmRemoveOpen(false);
    }
  };

  const submitEdit = useCallback(
    async ({ value }: { value: string }) => {
      try {
        await updatePersistentStorageMutation({
          variables: {
            updatePersistentStorageInput: {
              entries: [{ key: mKey, value }],
            },
          },
          refetchQueries: [
            {
              query: PersistentStorageQuery,
            },
          ],
        });
      } catch (error: unknown) {
        setErrorMessage((error as Error).message);
        return;
      }
    },
    [mKey, setErrorMessage, updatePersistentStorageMutation],
  );

  return (
    <Box sx={{ mt: '15px' }}>
      <TableContainer
        component={Paper}
        sx={{
          backgroundColor: isAP2Enabled ? white : midnight,
          backgroundImage: 'none',
          borderRadius: '4px',
          position: 'relative',
        }}
      >
        {loading ? (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              height: '100%',
              zIndex: '100',
              backgroundColor: 'transparent',
              position: 'absolute',
            }}
          >
            <CircularProgress size="50px" thickness={4} />
          </Box>
        ) : null}

        <Stack direction="row" alignItems="center" gap={2} px={5} pt={4}>
          <Stack flexDirection="column">
            <Grid sx={{ position: 'relative' }}>
              <InputField
                label="Query for persistant keys"
                value={queryPersistantValue}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handlePersistentQuery();
                    isBackButtonDisabled.current = true;
                  }
                }}
                placeholder=""
                customSx={{
                  borderRadius: '4px',
                  width: '350px',
                  mt: 0,
                  '& .MuiInputBase-input.Mui-disabled': {
                    WebkitTextFillColor: midnight,
                  },
                }}
                onChange={(e) => {
                  setQueryPersistantValue((e.target as HTMLInputElement).value);
                }}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      size="small"
                      onClick={() => {
                        clearPersistantData();
                        isBackButtonDisabled.current = true;
                      }}
                      sx={{
                        color: midnight,
                        opacity: queryPersistantValue ? 1 : 0,
                      }}
                    >
                      <IconClearBox />
                    </IconButton>
                  ),
                }}
              />
            </Grid>
          </Stack>
          <SecondaryButton
            buttonTitle="Query"
            customSx={{ minWidth: '84px' }}
            onClick={() => {
              handlePersistentQuery();
              isBackButtonDisabled.current = true;
            }}
            disabled={!queryPersistantValue?.trim()?.length}
          />
        </Stack>
        <Table aria-label="simple table" stickyHeader>
          <TableHead>
            <TableRow
              sx={{
                '& .MuiTableCell-root': {
                  fontSize: 14,
                  fontWeight: 700,
                  backgroundColor: white,
                  pb: 0,
                },
              }}
            >
              <TableCell align="left" width="20" />
              <TableCell
                align="left"
                sx={{
                  color: dusk,
                }}
              >
                Created Date
              </TableCell>
              <TableCell
                align="left"
                sx={{
                  color: dusk,
                }}
              >
                Key
              </TableCell>
              <TableCell
                align="left"
                sx={{
                  color: dusk,
                }}
              >
                Updated Date
              </TableCell>
              <TableCell
                width="130"
                align="left"
                sx={{
                  color: dusk,
                }}
              >
                Value
              </TableCell>
              <TableCell
                align="left"
                sx={{
                  color: dusk,
                }}
              >
                Version
              </TableCell>
              <TableCell align="left"></TableCell>
            </TableRow>
          </TableHead>
          {data?.length && data.length > 0 ? (
            <TableBody>
              {data?.map((item) => (
                <TableRow
                  key={item.key}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                    '& .MuiTableCell-root': {
                      fontSize: 16,
                      fontWeight: 600,
                    },
                    overflowWrap: 'anywhere',
                  }}
                >
                  <TableCell
                    align="left"
                    sx={{
                      width: '20px',
                    }}
                  />
                  <TableCell align="left">
                    {formatDate(
                      item.createdDate?.toDateTime,
                      'MM/dd/yyyy - HH:mm',
                    )}
                  </TableCell>
                  <TableCell align="left">
                    <Button
                      id="view-button"
                      aria-controls={isOpenView ? 'view-value' : undefined}
                      aria-haspopup="true"
                      aria-expanded={isOpenView ? 'true' : undefined}
                      onClick={(e) => handleOpenView(e, item.key || '')}
                      sx={{
                        padding: 0,
                        color: blueglass,
                        textTransform: 'capitalize',
                        fontSize: '16px',
                        fontWeight: 700,
                        minWidth: 'unset',
                      }}
                    >
                      View Key
                    </Button>
                  </TableCell>
                  <TableCell align="left">
                    {formatDate(
                      item.updatedDate?.toDateTime,
                      'MM/dd/yyyy - HH:mm',
                    )}
                  </TableCell>
                  <TableCell align="left">
                    <Button
                      id="view-button"
                      aria-controls={isOpenView ? 'view-value' : undefined}
                      aria-haspopup="true"
                      aria-expanded={isOpenView ? 'true' : undefined}
                      onClick={(e) => handleOpenView(e, item.value || '')}
                      sx={{
                        padding: 0,
                        color: blueglass,
                        textTransform: 'capitalize',
                        fontSize: '16px',
                        fontWeight: 700,
                        minWidth: 'unset',
                      }}
                    >
                      View Value
                    </Button>
                  </TableCell>
                  <TableCell align="left">{item.version}</TableCell>
                  <TableCell align="left">
                    <Stack direction="row" sx={{ justifyContent: 'flex-end' }}>
                      <Box
                        sx={{
                          width: '24px',
                          height: '24px',
                          cursor: 'pointer',
                          mr: 2,
                        }}
                        onClick={() =>
                          handleEdit({
                            key: item.key || '',
                            value: item.value || '',
                          })
                        }
                      >
                        <Edit sx={{ color: dusk }} />
                      </Box>
                      <Box
                        sx={{
                          width: '24px',
                          height: '24px',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          setKeyToRemove(item.key || '');
                          setIsConfirmRemoveOpen(true);
                        }}
                      >
                        <Delete sx={{ color: dusk, mr: 2 }} />
                      </Box>
                    </Stack>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          ) : (
            <TableRow
              sx={{
                '&:last-child td, &:last-child th': { border: 0 },
                '& .MuiTableCell-root': {
                  fontSize: 16,
                  fontWeight: 600,
                },
                overflowWrap: 'anywhere',
              }}
            >
              <TableCell align="left" />
              <TableCell align="left" />
              <TableCell align="right" />
              <TableCell align="left">
                <Typography sx={{ fontSize: '16px', color: dusk }}>
                  No result found
                </Typography>
              </TableCell>
              <TableCell align="left" />
              <TableCell align="left" />
            </TableRow>
          )}
        </Table>
      </TableContainer>

      <Pagination
        isBackDisabled={isBackButtonDisabled.current}
        isNextDisabled={!pageInfo?.hasNextPage}
        handleBackClick={() => {
          handlePersistentQuery('back');
          isBackButtonDisabled.current = true;
        }}
        handleNextClick={() => {
          handlePersistentQuery('next');
          isBackButtonDisabled.current = false;
        }}
        containerSx={{ mt: '15px' }}
      />

      {isEditOpen ? (
        <ModifyKeyDialog
          open={isEditOpen}
          handleClose={() => setIsEditOpen(false)}
          mKey={mKey}
          mValue={mValue}
          onSubmit={submitEdit}
        />
      ) : null}

      <ConfirmDialog
        title="Confirm that you would like to delete this key"
        open={isConfirmRemoveOpen}
        handleClose={() => setIsConfirmRemoveOpen(false)}
        handleConfirm={() => handleConfirmRemove(keyToRemove)}
        ctaTitle="Delete"
      />

      <Menu
        id="view-value"
        MenuListProps={{
          'aria-labelledby': 'view-button',
        }}
        anchorEl={anchorEl}
        open={isOpenView}
        onClose={handleCloseView}
        anchorOrigin={{ vertical: 40, horizontal: -300 }}
        PaperProps={{
          sx: {
            width: '600px',
            filter: 'unset !important',
            boxShadow: '0px 12px 60px 0px rgba(24, 24, 24, 0.12) !important',
          },
        }}
      >
        <Stack sx={{ p: '24px', background: white, borderRadius: '4px' }}>
          <Stack direction="row" alignItems="center">
            <Box sx={{ width: '24px' }} />
            <Typography
              sx={{
                color: midnight,
                flex: 1,
                fontSize: '18px',
                fontWeight: 700,
                textAlign: 'center',
              }}
            >
              View Value
            </Typography>
            <Box
              sx={{ width: '24px', cursor: 'pointer' }}
              onClick={handleCloseView}
            >
              <IconClose />
            </Box>
          </Stack>

          <Box
            sx={{
              background: eggshell,
              padding: '15px 22px',
              maxHeight: '200px',
              overflowY: 'scroll',
              borderRadius: '4px',
            }}
          >
            <Typography sx={{ color: midnight, wordWrap: 'break-word' }}>
              {viewValue}
            </Typography>
          </Box>
        </Stack>
      </Menu>
    </Box>
  );
};

export default PersistantStorageTable;
