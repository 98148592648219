import React, { Suspense, lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Box, CssBaseline, styled, Stack } from '@mui/material';
import { useAuthContext } from './components/auth/AuthContext';
import { NavDrawer } from './components/navigationBar/NavDrawer';
import { Footer } from './components/footer';
import { AppContextProvider } from './context/AppContext';
import { ConfigToolThemeProvider } from './theme/Theme';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { getRecaptchaSiteKey } from './components/auth/envUtils';
import { useNotifiEnv } from './components/auth/NotifiEnvContext';
import { ReportingView } from './pages/Reporting';
import { AlertManager } from './pages/AlertManager';
import { EditTopicView } from './pages/AlertManager/EditTopicView';
import { AlertManagerTopicsViewContainer } from './pages/AlertManager/AlertManagerTopicsViewContainer';
import { AlertManagerConfigsViewContainer } from './pages/AlertManager/AlertManagerConfigsViewContainer';
import { AlertManageStorageViewContainer } from './pages/AlertManager/AlertManageStorageViewContainer';
import { useFeatureFlag } from '@components/auth/FeatureFlagContext';
import RedirectLoginModal from '@components/modal/RedirectLoginModal';
import { TwoFAInputModal } from '@components/modal/TwoFAInputModal';

const CardConfig = lazy(() =>
  import('./pages/CardConfig').then((module) => ({
    default: module.CardConfig,
  })),
);

const SubscriptionCardConfigListContainer = lazy(() =>
  import(
    './pages/CardConfig/subscription/SubscriptionCardConfigListContainer'
  ).then((module) => ({ default: module.SubscriptionCardConfigListContainer })),
);
const SubscriptionCardConfigViewContainer = lazy(() =>
  import(
    './pages/CardConfig/subscription/SubscriptionCardConfigViewContainer'
  ).then((module) => ({ default: module.SubscriptionCardConfigViewContainer })),
);
const LogIn = lazy(() =>
  import('./pages/LogIn').then((module) => ({ default: module.LogIn })),
);
const Setup2Fa = lazy(() =>
  import('./pages/Setup2fa').then((module) => ({ default: module.Setup2fa })),
);
const Login2Fa = lazy(() =>
  import('./pages/Login2fa').then((module) => ({ default: module.Login2fa })),
);
const LogInDev = lazy(() =>
  import('./pages/LoginDev').then((module) => ({ default: module.LoginDev })),
);
const Guide = lazy(() =>
  import('./pages/Guide').then((module) => ({ default: module.Guide })),
);
const Introduction = lazy(() =>
  import('./pages/Guide/Introduction').then((module) => ({
    default: module.Introduction,
  })),
);
const CardConfigGuide = lazy(() =>
  import('./pages/Guide/CardConfigGuide').then((module) => ({
    default: module.CardConfigGuide,
  })),
);
const Send = lazy(() =>
  import('./pages/Send').then((module) => ({ default: module.Send })),
);
const SignUp = lazy(() =>
  import('./pages/SignUp').then((module) => ({ default: module.SignUp })),
);
const AuthRouter = lazy(() =>
  import('./AuthRouter').then((module) => ({ default: module.AuthRouter })),
);
const ResetPasswordView = lazy(() =>
  import('./pages/reset').then((module) => ({
    default: module.ResetPasswordView,
  })),
);
const EmailConfirmedView = lazy(() =>
  import('./pages/EmailConfirmed/EmailConfirmedView').then((module) => ({
    default: module.EmailConfirmedView,
  })),
);
const SetNewPasswordView = lazy(() =>
  import('./pages/reset/SetNewPasswordView').then((module) => ({
    default: module.SetNewPasswordView,
  })),
);
const CommunityView = lazy(() =>
  import('./pages/Community').then((module) => ({
    default: module.CommunityView,
  })),
);
const AccountSettings = lazy(() =>
  import('./pages/AccountSettings').then((module) => ({
    default: module.AccountSettings,
  })),
);

const InvitedUsrSetPassword = lazy(() =>
  import('./pages/reset/InvitedUsrSetPassword').then((module) => ({
    default: module.InvitedUsrSetPassword,
  })),
);

const Container = styled(Box)`
  display: flex;
`;

const Main = styled(Box)`
  flex-grow: 1;
`;

const LoggedInContainer: React.FC = () => {
  const { isAP2Enabled, loaded } = useFeatureFlag();
  if (loaded) {
    return isAP2Enabled ? (
      <Navigate to="/alert" replace />
    ) : (
      <Navigate to="/cards" replace />
    );
  } else {
    return null;
  }
};

const AlertContainer: React.FC = () => {
  const authState = useAuthContext();
  if (authState.type === 'loggedIn') {
    return <LoggedInContainer />;
  } else {
    return <Navigate to="/cards" replace />;
  }
};

export const App: React.FC = () => {
  const authState = useAuthContext();
  const { notifiEnv } = useNotifiEnv();
  const { loaded } = useFeatureFlag();

  const currentURL = window.location.href;
  const isDevelopment = !currentURL.includes('admin.notifi.network');
  const isNotifiDeveloper = localStorage.getItem('isNotifiDeveloper');

  return (
    <ConfigToolThemeProvider>
      <AppContextProvider>
        <CssBaseline />
        {!isDevelopment || isNotifiDeveloper ? null : <RedirectLoginModal />}
        <Container>
          {loaded && <NavDrawer />}
          <Stack
            flexDirection="column"
            sx={{
              height: { laptop: '100vh', xs: 'auto' },
              flex: 1,
              position: 'relative',
            }}
          >
            <Main
              component="main"
              sx={{
                flex: 1,
                ...(authState.type === 'loggedOut'
                  ? {
                      height: '100vh',
                      position: 'relative',
                      overflowY: { laptop: 'hidden', xs: 'auto' },
                      overflowX: 'hidden',
                    }
                  : null),
              }}
            >
              {authState.type === 'loggedIn' ? <>{/* <Toolbar /> */}</> : null}
              <Suspense>
                <Routes>
                  <Route
                    element={
                      <AuthRouter
                        requiredAuthState="loggedOut"
                        redirectURL="/"
                      />
                    }
                  >
                    <Route
                      path="/signup"
                      element={
                        <GoogleReCaptchaProvider
                          reCaptchaKey={getRecaptchaSiteKey(notifiEnv)}
                          useEnterprise={true}
                        >
                          <SignUp />
                        </GoogleReCaptchaProvider>
                      }
                    />
                    <Route path="/reset" element={<ResetPasswordView />} />
                    <Route
                      path="/reset_password"
                      element={<SetNewPasswordView />}
                    />
                    <Route
                      path="/set_password"
                      element={<InvitedUsrSetPassword />}
                    />

                    <Route path="/login" element={<LogIn />} />
                    <Route path="/2fa-setup" element={<Setup2Fa />} />
                    <Route path="/2fa-login" element={<Login2Fa />} />
                    <Route path="/login-dev" element={<LogInDev />} />
                    <Route
                      path="/confirm_email"
                      element={<EmailConfirmedView />}
                    />
                  </Route>
                  <Route
                    element={
                      <AuthRouter
                        requiredAuthState="loggedIn"
                        redirectURL="/login"
                      />
                    }
                  >
                    <Route index element={<AlertContainer />} />

                    <Route path="/alert" element={<AlertManager />}>
                      <Route
                        index
                        element={<AlertManagerTopicsViewContainer />}
                      />
                      <Route
                        path="configs"
                        element={<AlertManagerConfigsViewContainer />}
                      />
                      <Route
                        path="storage"
                        element={<AlertManageStorageViewContainer />}
                      />
                      <Route path="edit/:cardId" element={<EditTopicView />} />
                    </Route>

                    <Route path="/cards" element={<CardConfig />}>
                      <Route
                        index
                        element={<SubscriptionCardConfigListContainer />}
                      />
                      <Route
                        path="subscription"
                        element={<SubscriptionCardConfigListContainer />}
                      />
                      <Route
                        path="subscription/:cardId"
                        element={<SubscriptionCardConfigViewContainer />}
                      />
                    </Route>
                    <Route path="send" element={<Send />} />
                    <Route path="community" element={<CommunityView />} />
                    <Route path="reporting" element={<ReportingView />} />
                    <Route path="settings" element={<AccountSettings />} />
                  </Route>
                  <Route path="guide" element={<Guide />}>
                    <Route index element={<Introduction />} />
                    <Route path="cards" element={<CardConfigGuide />} />
                  </Route>
                </Routes>
              </Suspense>
            </Main>
            {loaded && <Footer />}
          </Stack>
          <TwoFAInputModal />
        </Container>
      </AppContextProvider>
    </ConfigToolThemeProvider>
  );
};
