import { HealthCheckTopicTypeItem } from '@components/cardConfig/common/TopicTypeItem';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React from 'react';

export type AlertFrequencyItem =
  | 'DAILY'
  | 'ONE_MINUTE'
  | 'THREE_MINUTES'
  | 'FIVE_MINUTES'
  | 'QUARTER_HOUR'
  | 'ALWAYS'
  | 'HOURLY';

type MenuItemConfigs<Config> = Readonly<{
  [K in AlertFrequencyItem]: Config;
}>;

export const ALERT_FREQUENCY_LABELS: MenuItemConfigs<string> = {
  ALWAYS: 'No Maximum',
  ONE_MINUTE: 'One minute',
  THREE_MINUTES: 'Three minutes',
  FIVE_MINUTES: 'Five minutes',
  QUARTER_HOUR: 'Fifteen minutes',
  HOURLY: 'Hourly',
  DAILY: 'Daily',
};

export const ALERT_FREQUENCY_ITEMS = Object.keys(ALERT_FREQUENCY_LABELS).map(
  (key) => key as AlertFrequencyItem,
);

export type Props = Readonly<{
  currentItem: HealthCheckTopicTypeItem;
  setCurrentItem: (currentItem: HealthCheckTopicTypeItem) => void;
  setValidationError: (validationError: string | null) => void;
}>;

export const AlertFrequencySelectField: React.FC<Props> = ({
  currentItem,
  setCurrentItem,
  setValidationError,
}: Props) => {
  return (
    <FormControl sx={{ marginX: 2, flexGrow: 1 }}>
      <InputLabel id="alert-frequency-select-label">Alert Frequency</InputLabel>
      <Select
        labelId="alert-frequency-select-label"
        label="Alert-frequency"
        value={currentItem.alertFrequency}
        fullWidth
        onChange={(e) => {
          setValidationError(null);
          setCurrentItem({
            ...currentItem,
            alertFrequency: e.target.value,
          });
        }}
      >
        {ALERT_FREQUENCY_ITEMS.map((item) => (
          <MenuItem key={item} value={item}>
            {ALERT_FREQUENCY_LABELS[item]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
