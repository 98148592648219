import React from 'react';
import { Stack, SxProps } from '@mui/material';
import { ReactComponent as RightArrow } from '../assets/icn-right-arrow.svg';
import { ReactComponent as LeftArrow } from '../assets/icn-left-arrow.svg';
import { SecondaryButton } from '@components/buttons/SecondaryButton';
import { dusk } from 'src/constants/colors';

type Props = {
  isBackDisabled?: boolean;
  isNextDisabled?: boolean;
  handleBackClick: () => void;
  handleNextClick: () => void;
  containerSx?: SxProps;
};

const Pagination: React.FC<Props> = ({
  isBackDisabled,
  isNextDisabled,
  handleBackClick,
  handleNextClick,
  containerSx,
}) => {
  return (
    <Stack
      sx={{
        position: 'relative',
        flexDirection: 'row',
        justifyContent: 'center',
        mb: 1.5,
        ...containerSx,
      }}
    >
      <Stack flexDirection={'row'} justifyContent={'center'}>
        <SecondaryButton
          disabled={isBackDisabled}
          customSx={{
            fontFamily: 'Rota',
            fontSize: '16px',
            fontWeight: 700,
            lineHeight: 'normal',
            fontStyle: 'normal',
            color: dusk,
            textTransform: 'capitalize',
            border: 'none !important',
          }}
          onClick={handleBackClick}
          startIcon={<LeftArrow />}
          buttonTitle="Back"
        />

        <SecondaryButton
          customSx={{
            fontFamily: 'Rota',
            fontSize: '16px',
            fontWeight: 700,
            lineHeight: 'normal',
            fontStyle: 'normal',
            color: dusk,
            textTransform: 'capitalize',
            border: 'none !important',
          }}
          onClick={handleNextClick}
          endIcon={<RightArrow />}
          buttonTitle="Next"
          disabled={isNextDisabled}
        />
      </Stack>
    </Stack>
  );
};

export default Pagination;
