import { CardVersion } from '@components/cardConfig/common/CardConfigItemV1';
import { TenantConfigType } from 'src/services/gql/generated';

export const getCardConfigTypeDisplay = (configType: TenantConfigType) => {
  switch (configType) {
    case TenantConfigType.SUBSCRIPTION_CARD:
      return 'Subscription';
  }
};

export const getCardNavigationUrl = (
  configType: TenantConfigType,
  cardId: string,
) => {
   return `/cards/subscription/${cardId}`;
};

export const getTypeFromVersion = (version: CardVersion): TenantConfigType => {
  switch (version) {
    case 'v1':
      return TenantConfigType.SUBSCRIPTION_CARD;
  }
};

export const getSign = (thresholdType: string, num: string) => {
  switch (thresholdType) {
    case 'percentage':
      return Math.round(Number(num) * 1000) / 10 + '%';
    case 'price':
      return '$' + num;
    case 'x':
      return num + 'x';
    default:
      return num;
  }
};
